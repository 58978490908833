import React from "react"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import { Section, MarkdownContent } from "../components/Section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PatternImg from "../images/line-pattern.png"
import RequestForm from "../components/RequestForm"
import "@material/react-select/dist/select.css"
import StickyBox from "react-sticky-box"

class CertifiedBuildings extends React.Component {
  render() {
    const { location } = this.props;
    const formId = "Request a Quote";
    return (
      <Layout location = { location }>
        <SEO title="Certified Buildings" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className="position-relative">
            <Breadcrumb className="beadcrumbs-outer">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Certified Buildings</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className="section" pt="100px" pb="100px">
          <Container>
            <Row>
              <Col lg={7} xl={8}>
                <MarkdownContent>
                  <span>Certified Buildings</span>
                  <h1>Certified vs. Non-Certified Buildings</h1>
                  <p>
                    If you’ve been doing much research about metal buildings,
                    you’ve probably come across the terms “certified metal
                    buildings” and “non-certified metal buildings.” What do
                    those terms mean, exactly? To glance at either building from
                    the outside, you might not be able to tell which is
                    certified and which isn’t. The real difference is what’s on
                    the inside.
                  </p>

                  <h2>What Does “Certified” Mean, Exactly?</h2>
                  <p>
                  Our name is Carports R Us, but we also offer garages, barns, workshops, and other custom-designed steel building options. Plus, we can certify any of our metal buildings for you! If you’re ready to get started, just give us a call at <a href="tel:8773304846">(877) 330-4846</a>, or email us at <a href="mailto:carportrus@gmail.com" target="_blank" rel="noopener noreferrer">carportrus@gmail.com</a>.
                  </p>
                </MarkdownContent>
              </Col>
              <Col lg={5} xl={4}>
                <StickyBox offsetTop={170}>
                  <div className="form-wrapper">
                    <h2 className="form-title">Request a Quote</h2>
                    <RequestForm location = { location } formId = { formId } />
                  </div>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default CertifiedBuildings
